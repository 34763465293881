<template>
    <div class="cm-contain">
        <div class="cm-contain-head">
            <div class="head-title">学生管理</div>
            <div class="head-botton">
                <el-button @click="addStudent">创建学生</el-button>
            </div>
        </div>
        <div class="cm-contain-middle">
            <div class="middle-title">班级筛选：</div>
            <el-select v-model="classValue" placeholder="请选择班级" @change="changeStudent" clearable>
                <el-option v-for="item in classList" :key="item.c_id" :label="item.c_name" :value="item.c_id"></el-option>
            </el-select>
        </div>
        <div class="cm-contain-main">
            <el-table :data="studentData" style="width: 100%" :header-cell-style="headStyle" :cell-style="cellStyle">
                <el-table-column prop="s_name" label="学生名称" align="left"></el-table-column>
                <el-table-column prop="s_account" label="账号" align="center"></el-table-column>
                <el-table-column prop="c_name" label="班级" align="center"></el-table-column>
                <el-table-column label="头像" align="center">
                    <template slot-scope="scope">
                        <img class="avatar" :src="scope.row.s_ico ? scope.row.s_ico : require('../../assets/images/user_img.png')" alt="">
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="300">
                    <template slot-scope="scope">
                        <el-button size="small" @click="resetBtn(scope.row.s_id)">重置密码</el-button>
                        <el-button size="small" @click="editBtn(scope.row, scope.row.s_id)">编辑</el-button>
                        <el-button size="small" @click="deleteBtn(scope.row.s_id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="cm-contain-bottom">
            <el-pagination class="pages-right" :current-page.sync="studentsPages.currentPageNum"
                           :page-size="studentsPages.eachPageNum" :total="studentsPages.total"
                           layout="prev, pager, next, jumper" @current-change="studentCurrentChange">
            </el-pagination>
        </div>
        <el-dialog :title="adminOperTitle" :visible.sync="dialogAddAdmin" center width="30%" @close="resetForm()" :close-on-click-modal="false">
            <el-form :model="addAdminForm" ref="addAdminForm" :rules="adminRules" label-position="center" class="info-form">
                <el-form-item label="名称" :label-width="formLabelWidth" prop="s_name">
                    <el-input v-model="addAdminForm.s_name" autocomplete="off" placeholder="1-20字符"></el-input>
                </el-form-item>
                <el-form-item label="账号" :label-width="formLabelWidth" prop="s_account">
                    <el-input v-model="addAdminForm.s_account" autocomplete="off" placeholder="1-20字符"></el-input>
                </el-form-item>
                <el-form-item label="密码" v-if="adminOperTitle === '创建学生'" :label-width="formLabelWidth" prop="s_password">
                    <el-input v-model="addAdminForm.s_password" placeholder="6-16字符"></el-input>
                </el-form-item>
                <el-form-item label="班级" :label-width="formLabelWidth" prop="c_id">
<!--                    @change="changeStudent"-->
                    <el-select v-model="addAdminForm.c_id" placeholder="请选择班级" clearable>
                        <el-option v-for="item in classList" :key="item.c_id" :label="item.c_name" :value="item.c_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="头像" :label-width="formLabelWidth" prop="image">
                    <el-button type="primary" v-model="addAdminForm.image" class="upload-btn" size="medium">
                        <span>{{addAdminForm.image ? '重新上传' : '上传头像'}}</span>
                        <input type="file" accept="image/*" title="" @change="changeFile($event)">
                    </el-button>
                    <div class="up-img-show" v-show="addAdminForm.image">
                        <img :src="addAdminForm.image" alt />
                    </div>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddAdmin = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addAdminForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "classManage",
        data() {
            return {
                classValue: '',
                classList: [],
                studentsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                studentData: [],
                adminOperTitle: '',
                dialogAddAdmin: false,
                addAdminForm: {
                    s_id: '',
                    s_name: '',
                    s_account: '',
                    s_password: '',
                    c_id: '',
                    image: '',
                    imageFile:''
                },
                formLabelWidth: '80px',
                adminRules: {
                    s_name: [
                        { required: true, message: '请输入学生名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    s_account: [
                        { required: true, message: '请输入账号', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    s_password: [
                        { required: true, message: '请输入长度在 6 到 16 个字符', trigger: 'blur' },
                        { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' },
                    ],
                    c_id: [
                        { required: true, message: '请选择班级', trigger: 'change' }
                    ]
                },
                distinguishBtn: '',
            }
        },
        mounted() {
            this.getClassList();
            this.getStudentList();
        },
        methods: {
            getClassList() {
                this.$shttp.axiosGet(this.$api.showClass, (res) => {
                    if (res.code === 200) {
                        this.classList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getStudentList() {
                let params = {
                    page: this.studentsPages.currentPageNum,
                    limit: this.studentsPages.eachPageNum,
                }
                if (this.classValue) {
                    params.c_id = this.classValue
                }
                this.$shttp.axiosGetBy(this.$api.showStudent, params, (res) => {
                    if (res.code === 200) {
                        this.studentData = res.data.data;
                        this.studentsPages.total = res.data.total;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            studentCurrentChange(val) {
                this.studentsPages.currentPageNum = val;
                this.getStudentList();
            },
            changeStudent() {
                this.getStudentList();
            },
            addStudent() {
                this.dialogAddAdmin = true;
                this.adminOperTitle = '创建学生';
                this.distinguishBtn = 'add';
            },
            editBtn(row, id) {
                this.dialogAddAdmin = true;
                this.adminOperTitle = '编辑学生';
                this.distinguishBtn = 'edit';
                this.addAdminForm.s_id = row.s_id;
                this.addAdminForm.s_name = row.s_name;
                this.addAdminForm.s_account = row.s_account;
                this.addAdminForm.c_id = row.c_id;
                this.addAdminForm.image = row.s_ico;
            },
            changeFile(event) {
                if (event.target.files.length === 0) return;
                let fileList = event.target.files[0];
                this.addAdminForm.imageFile = fileList;
                let url = '';
                let reader = new FileReader();
                reader.readAsDataURL(fileList);
                let that = this;
                reader.onload = function (e) {
                    url = this.result.substring(this.result.indexOf(',') + 1);
                    that.addAdminForm.image = "data:image/png;base64," + url;
                }
            },
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.distinguishBtn === 'add') {
                            let formData = new FormData();
                            formData.append('s_name', this.addAdminForm.s_name);
                            formData.append('s_account', this.addAdminForm.s_account);
                            formData.append('s_password', this.addAdminForm.s_password);
                            formData.append('c_id', this.addAdminForm.c_id);
                            if (this.addAdminForm.imageFile) {
                                formData.append('image', this.addAdminForm.imageFile)
                            }
                            this.$shttp.axiosPost(this.$api.saveStudent, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.studentsPages.currentPageNum = 1;
                                    this.getStudentList();
                                    this.dialogAddAdmin = false;
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        } else if (this.distinguishBtn === 'edit') {
                            let formData = new FormData();
                            formData.append('s_id', this.addAdminForm.s_id);
                            formData.append('s_name', this.addAdminForm.s_name);
                            formData.append('s_account', this.addAdminForm.s_account);
                            formData.append('c_id', this.addAdminForm.c_id);
                            if (this.addAdminForm.imageFile) {
                                formData.append('image', this.addAdminForm.imageFile)
                            }
                            this.$shttp.axiosPost(this.$api.saveStudent, formData, (res) => {
                                if (res.code === 200) {
                                    this.$message.success(res.msg);
                                    this.dialogAddAdmin = false;
                                    this.getStudentList();
                                    this.resetForm();
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            }, (err) => {
                                console.log(err)
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            deleteBtn(id) {
                this.$confirm('是否删除该学生，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$shttp.axiosGetBy(this.$api.delStudent, {s_id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getStudentList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            resetBtn(id) {
                this.$confirm('重置密码,默认123456', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$shttp.axiosGetBy(this.$api.resetStuPwd, {s_id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getStudentList();
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消重置密码')
                });
            },
            resetForm() {
                this.$refs.addAdminForm.resetFields();
                this.addAdminForm = {
                    s_id: '',
                    s_name: '',
                    s_account: '',
                    s_password: '',
                    c_id: '',
                    image: '',
                    imageFile:''
                }
            },
            headStyle() {
                return 'background:#F5F5F5;fontWeight:400;fontFamily:MicrosoftYaHei;padding-left:25px;color:#333333'
            },
            cellStyle() {
                return 'padding-left:25px'
            },
        }
    }
</script>

<style scoped lang="scss">
    .avatar {
        width: 32px;
        height: 32px;
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
    }
    .upload-btn {
        position: relative;
        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .up-img-show {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border: 1px solid #ccc;
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 10px;
        overflow: hidden;
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .cm-contain {
        height: 100%;
    }

    .cm-contain-head {
        height: 64px;
        line-height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #EAEAEA;
    }

    .head-title {
        margin-left: 40px;
    }

    .head-botton {
        margin-right: 40px;
    }

    .head-botton .el-button {
        width: 108px;
        /*height: 36px;*/
        background-color: #005496;
        color: white;
    }

    .cm-contain-middle {
        height: 63px;
        line-height: 63px;
        display: flex;
        flex-direction: row;
    }

    .middle-title {
        margin-left: 40px;
    }

    .cm-contain-main {
        height: calc(100% - 177px);
        overflow: auto;
    }

    .cm-contain-main img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    .cm-contain-main span:first-child {
        cursor: pointer;
    }

    .cm-contain-main span:last-child {
        cursor: pointer;
        margin-left: 19px;
    }

    .cm-contain-bottom {
        margin-top: 10px;
        align-items: center;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
    }

    .el-pagination {
        margin: 0 auto;
    }
    /*start滚动条*/
    ::-webkit-scrollbar {
        width: 0.25rem;
        height: 0.25rem;
        background-image: linear-gradient(135deg, #1DE9B6 0%, rgba(8, 196, 219, 0.5) 72%, rgba(0, 182, 234, 0.3) 100%);
    }
    ::-webkit-scrollbar-track {
        border-radius: 0;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-image: linear-gradient(135deg, #1DE9B6 0%, #08c4db 72%, #057494 100%);
        transition: all .2s;
        border-radius: 0.25rem;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(95, 95, 95, 0.7);
    }
    /*end滚动条*/
    /*Start创建学生弹窗 */
    .sm-create-dialog{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
    }
    .sm-create-dialog .create-contain {
        width: 450px;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 12;
        background: #fff;
    }
    .create-head{
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid #EAEAEA;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    .create-head span:first-child{
        margin-left: 32px;
    }
    .create-head span:last-child {
        margin-right: 16px;
        cursor: pointer;
    }
    .create-contain .el-input__inner{
        height: 30px;
        width: 334px;
    }
    .create-button{
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
    }
    /*End创建学生弹窗*/
</style>
<style>
    .sm-create-dialog .el-input__inner{
        width: 334px;
        height: 30px;
    }
    .sm-create-dialog .el-button{
        width: 100px;
        height: 32px;
        line-height: 0;
    }
    .sm-create-dialog .el-button--danger{
        color: #ffffff;
        background-color: #3296FA;
        border: none;
    }
</style>