import {get, post} from "./request";

export const importExcel = (formdata) => {
    return post('tools/import', formdata)
}
export const categoryList = (params) => {
    return get('admin/resourceClassList', params)
}
export const courseList = (params) => {
    return get('admin/courseLists', params)
}
export const banners = () => {
    return get('banner/list')
}
export const news = (params) => {
    return get('news/list', params)
}

export const addResources = (formdata) => {
    return post("admin/addresource", formdata)
}