<template>
    <div class="ed-body">
        <div class="ed-tab">
            <div class="en-left">
                <el-tabs tabPosition="left" v-model="activeName" @tab-click="toggleEducationTab">
                    <el-tab-pane label="班级管理" name="class">
                        <ClassManage ref="classManage" />
                    </el-tab-pane>
                    <el-tab-pane label="学生管理" name="student">
                        <StudentManage ref="studentManage" />
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>

    </div>
</template>

<script>
    import StudentManage from '@/components/qualityCourse/studentManage.vue'
    import ClassManage from '@/components/qualityCourse/classManage.vue'

    export default {
        name: "educational",
        components: {
            StudentManage,
            ClassManage,
        },
        data() {
            return {
                activeName: 'class',
            }
        },
        methods: {
            toggleEducationTab() {
                this.$refs.classManage.getClassList();
                this.$refs.studentManage.getClassList();
                this.$refs.studentManage.getStudentList();
            }
        },
    }
</script>

<style>
    .ed-body {
        height: 100%;
        background-color: #F2F2F2;
        width: 100%;
    }

    .ed-tab {
        padding: 20px 240px 0;
        height: calc(100% - 20px);
    }

    .en-left {
        width: 100%;
    }

    .en-left .el-tabs--left .el-tabs__header.is-left {
        width: 240px;
        background: #fff;
        margin-right: 20px;
        height: calc(100vh - 90px);
    }

    .en-left .el-tabs--left .el-tabs__item.is-left {
        text-align: left;
        border-bottom: 1px solid #eaeaea
    }

    .en-left .el-tabs__item.is-active {
        color: #005496;
    }

    .en-left .el-tabs__active-bar {
        background-color: #005496;
    }

    .en-left .el-tabs--left .el-tabs__active-bar.is-left, .en-left .el-tabs--left .el-tabs__nav-wrap.is-left::after {
        left: 0;
        height: 22px !important;
        margin-top: 10px;
    }

    .en-left .el-tabs__item:hover {
        color: #005496;
        cursor: pointer;
    }

    .en-left .el-tabs__content {
        height: 100%;
        background: #fff;
    }

    .en-left .el-tab-pane {
        height: calc(100vh - 90px);
    }

    .en-left .el-tabs__nav-wrap::after {
        background-color: transparent;
    }

    .en-left .el-menu {
        background-color: transparent;
        border-right: none;
    }

    .en-left .el-menu-item.is-active {
        color: #3A9AFA;
    }

    .en-left .el-menu-item.is-active:before {
        content: "";
        border-left: 2px solid #3A9AFA;
        position: relative;
        left: -20px;
    }

    .en-left .el-menu-item:before {
        content: "";
        position: relative;
        left: -20px;
        border-left: 2px solid transparent;
    }

    .en-left .el-menu-item {
        text-align: left;
        padding: 0;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #eaeaea;
    }

    .ed-contain-left {
        height: 100%;
        width: 240px;
        background-color: white;
        border-radius: 5px;
        margin-right: 20px;
    }

    .ed-contain-right {
        width: calc(100% - 260px);
        background-color: white;
    }
</style>