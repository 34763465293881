import axios from "axios";

const axiosInstance = axios.create({
    timeout: 5000
});
const tokenArr = {
    1: "token",
    3: "sToken",
    4: "studentToken"

}

// 请求拦截器
axiosInstance.interceptors.request.use((config) => {
    let token = localStorage.getItem(tokenArr[Number(localStorage.getItem('role'))])
    if (token) {
        config.headers.Authorization = token
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

/* 相应拦截器 */
axiosInstance.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    return Promise.reject(error);
});

export const get = (url, params = {}) => {
    return axiosInstance.get(url, {params: params});
}

export const post = (url, formdata={}) => {
    return axiosInstance.post(url, formdata)
}

export default axiosInstance